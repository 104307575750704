import React, { useMemo, useState, useEffect, useRef } from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { Toaster, toast } from 'sonner';

const CheckTable = (props) => {
  const { columnsData, tableData, onEmailFetchSuccess, setTokens } = props;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const handleCheckboxChange = async (rowIndex) => {
    const newData = [...data];
    const profileId = newData[rowIndex].profile_id;
    const newStatus = newData[rowIndex].contacted === "true" ? "false" : "true";

    const promise = new Promise(async (resolve, reject) => {
      try {
        const token = Cookies.get("jwt");
        await axios.put(`https://praktbackendnew-production.up.railway.app/api/v1/profile/${profileId}/contacted`, {
          isContacted: newStatus === "true"
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        newData[rowIndex].contacted = newStatus;
        setData(newData);

        resolve(newStatus === "true" ? 'Contacted' : 'Not contacted');
      } catch (error) {
        console.error("Error updating contacted status:", error);
        reject('Error');
      }
    });

    toast.promise(promise, {
      loading: 'Updating...',
      success: (data) => `${data}`,
      error: 'Error updating status',
    });
  };

  const handleHittaMailClick = async (rowIndex) => {
    setIsLoading(true);
    const newData = [...data];
    const linkedinUrl = newData[rowIndex].linkedin_url;
  
    const promise = new Promise(async (resolve, reject) => {
      try {
        const token = Cookies.get("jwt");
        const response = await axios.put(`https://praktbackendnew-production.up.railway.app/api/v1/profile/profile`, {
          linkedin_url: linkedinUrl
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const { email, full_name, company } = response.data;

        console.log(response.data)
  
        if (email !== "null") {
          newData[rowIndex] = {
            ...newData[rowIndex],
            email: email,
            full_name: full_name,
            company: company,
          };
        } else {
          throw new Error('Email is null');
        }
  
        setData(newData);
        onEmailFetchSuccess();
  
        const tokenResponse = await axios.get("https://praktbackendnew-production.up.railway.app/api/v1/profile/tokens", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTokens(tokenResponse.data);
  
        resolve('Email found successfully!');
      } catch (error) {
        console.error("Error fetching email:", error);
        reject('Error fetching email.');
      } finally {
        setIsLoading(false);
      }
    });
  
    toast.promise(promise, {
      loading: 'Finding email...',
      success: (data) => `${data}`,
      error: 'Error finding email',
    });
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const tableDataMemo = useMemo(() => data, [data]);

  const tableInstance = useTable(
    {
      columns,
      data: tableDataMemo,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: tablePage,
    prepareRow,
    initialState,
  } = tableInstance;

  initialState.pageSize = 20;

  return (
    <>
      <Toaster richColors position="bottom-center" />
      <Card extra={"w-full h-full px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Tasks
          </div>
          <CardMenu />
        </header>

        <div ref={scrollContainerRef} className="mt-8 overflow-auto h-[400px]">
          <table
            {...getTableProps()}
            className="min-w-full border-collapse block lg:table"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead className="block lg:table-header-group">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index} className="block lg:table-row">
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-4 pb-[10px] text-start dark:border-navy-700 block lg:table-cell"
                      key={index}
                    >
                      <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps
()} className="block lg:table-row-group">
{tablePage.map((row, rowIndex) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps()} key={rowIndex} className="block lg:table-row">
      {row.cells.map((cell, cellIndex) => {
        let data = "";
        if (cell.column.Header === "CONTACTED") {
          data = (
            <div className="flex items-center gap-2">
              <Checkbox
                checked={cell.value === "true"}
                onChange={() => handleCheckboxChange(rowIndex)}
              />
              <p className="text-sm font-bold text-navy-700 dark:text-white">
              </p>
            </div>
          );
        } else if (cell.column.Header === "NAME") {
          data = (
            <div className="flex items-center">
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {cell.value}
              </p>
            </div>
          );
        } else if (cell.column.Header === "COMPANY") {
          data = (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {cell.value}
            </p>
          );
        } else if (cell.column.Header === "LINKEDIN URL") {
          data = (
            <a href={cell.value} className="text-sm font-bold text-navy-700 dark:text-white">
              {cell.value}
            </a>
          );
        } else if (cell.column.Header === "EMAIL") {
          data = cell.value ? (
            <a href={`mailto:${cell.value}`} className="text-sm font-bold text-blue-500 dark:text-white">
              Skicka mail
            </a>
          ) : (
            <button
              onClick={() => handleHittaMailClick(rowIndex)}
              className="text-sm font-bold text-navy-700 dark:text-white">
              Hitta mail
            </button>
          );
        }
        return (
          <td
            {...cell.getCellProps()}
            key={cellIndex}
            className="pt-[14px] pb-[16px] sm:text-[14px] block lg:table-cell"
          >
            {data}
          </td>
        );
      })}
    </tr>
  );
})}
</tbody>
</table>
{isLoading && <div>Laddar...</div>}
</div>
</Card>
</>
);
};

export default CheckTable;
