import React, { useEffect } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Cookies from "js-cookie"; // Import js-cookie
import { useLocation } from "react-router-dom"; // Import useLocation hook
import { toast } from 'sonner'; // Import toast from sonner
import { Checkmark } from 'react-checkmark'

const DevelopmentTable = () => {
  const location = useLocation();

  useEffect(() => {
    const fetchSessionStatus = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get("session_id");

      console.log("sessionid", sessionId);

      if (sessionId) {
        const jwtToken = Cookies.get("jwt"); // Get the JWT token from the cookies

        try {
          const response = await fetch(`https://praktbackendnew-production.up.railway.app/stripe/sessionStatus?session_id=${sessionId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${jwtToken}`, // Include the JWT token in the Authorization header
            },
          });

          console.log("RESPONSE", response);

          if (!response.ok) {
            throw new Error("Failed to fetch session status");
          }

          // Display toast message on success
          toast.success("Lagt till 10 tokens till ditt konto");

          const result = await response.json();
          console.log("Session status:", result);
        } catch (error) {
          console.error("Error fetching session status:", error);
        }
      }
    };

    fetchSessionStatus();
  }, [location.search]); // Run the effect when the location's search part changes

  // Function to handle button click and redirect to the dashboard
  const goToDashboard = () => {
    window.location.href = "http://app.praktiker/admin/default";
  };

  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Successful purchase
        </div>
        <Checkmark size='xxLarge' />
      </div>

      <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
        <table className="mt-8 h-max w-full" variant="simple" color="gray-500" mb="24px">
          <tbody>
            <tr>
              <td>
                {/* Render your successful logo here */}
                <p>Added 10 tokens to your account</p>
                {/* Button to go to dashboard */}
                <button
                  className="m-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  onClick={goToDashboard}
                >
                  Gå till dashboard
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default DevelopmentTable;
