import React, { useState } from "react";
import BarChart from "components/charts/BarChart";
import { barChartDataDailyTraffic } from "variables/charts";
import { barChartOptionsDailyTraffic } from "variables/charts";
import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Toaster, toast } from 'sonner';

const DailyTraffic = ({ onSaveSuccess, updateKontaktadeCount }) => {
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [saveStatus, setSaveStatus] = useState("");

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleSave = async () => {
    const token = Cookies.get('jwt');

    if (!token) {
      setSaveStatus("JWT token not found in cookies.");
      toast.error("JWT token not found in cookies.");
      return;
    }

    try {
      const response = await axios.post("https://praktbackendnew-production.up.railway.app/api/v1/profile/profiles", 
        { linkedin_url: linkedinUrl },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        }
      );

      if (response.status === 201) {
        setSaveStatus("LinkedIn URL saved successfully!");
        toast.success("LinkedIn URL saved successfully!");
        onSaveSuccess(); 

        if (typeof updateKontaktadeCount === 'function') {
          updateKontaktadeCount(prevCount => prevCount + 1);
        } else {
          console.error("updateKontaktadeCount is not a function");
        }
      } else {
        setSaveStatus("Failed to save LinkedIn URL.");
        toast.error("Failed to save LinkedIn URL.");
      }
    } catch (error) {
      console.error("Error saving LinkedIn URL:", error);
      setSaveStatus("Error saving LinkedIn URL.");
      toast.error("Error saving LinkedIn URL.");
    }
  };

  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-between">
          <div className="pt-2 ms-1">
            <p className="text-sm font-medium leading-4 text-gray-600">
              Enter Linkedin URL
            </p>
          </div>
          <div className="mt-2 flex items-start">
          </div>
        </div>

        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Enter LinkedIn URL"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
            className="p-2 border border-gray-300 rounded"
          />
          <button
            onClick={handleSave}
            className="mt-2 p-2 bg-[#4318ff] text-white rounded hover:bg-[#4318ff]"
          >
            Spara
          </button>
          {saveStatus && <p className="mt-2 text-sm">{saveStatus}</p>}
        </div>


      </div>
      <Toaster richColors position="bottom-center" />
    </Card>
  );
};

export default DailyTraffic;
