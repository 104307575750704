export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "COMPANY",
    accessor: "company",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CONTACTED",
    accessor: "contacted",
  },
  {
    Header: "LINKEDIN URL",
    accessor: "linkedin_url",
  }
];


export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "COMPANY",
    accessor: "company",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CONTACTED",
    accessor: "contacted",
  },
  {
    Header: "LINKEDIN URL",
    accessor: "linkedin_url",
  }
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "COMPANY",
    accessor: "company",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CONTACTED",
    accessor: "contacted",
  },
  {
    Header: "LINKEDIN URL",
    accessor: "linkedin_url",
  }
];
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "COMPANY",
    accessor: "company",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CONTACTED",
    accessor: "contacted",
  },
  {
    Header: "LINKEDIN URL",
    accessor: "linkedin_url",
  }
];

