import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import InputField from "components/fields/InputField";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import 'react-toastify/dist/ReactToastify.css';

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // State for terms acceptance
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (!termsAccepted) {
      setError("Please accept the terms of service.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post("https://praktbackendnew-production.up.railway.app/api/auth/register", {
        username: email,
        password: password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      console.log("DATA: ", data);
      if (data === "User registered successfully") {
        toast.success('Successfully registered!', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/auth/sign-in"); // navigate to dashboard or any other page after successful sign-up
      }
    } catch (error) {
      if (error.response.data === "Invalid email") {
        setError("Invalid email");
      } else if (error.response.data === "Invalid password") {
        setError("Your password is too short");
      } else {
        setError("Failed to create account. Please try again.");
      }
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign up section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign up!
        </p>
        {error && <p className="mb-4 text-sm text-red-500">{error}</p>}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 6 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Confirm Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Confirm Password*"
          placeholder="Min. 6 characters"
          id="confirm-password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        {/* Terms and Service */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="terms-checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            className="mr-2"
          />
          <label htmlFor="terms-checkbox" className="text-sm text-gray-600">
            I accept the
            <a
              href="https://praktiker.se/api/hello"
              className="text-blue-500 ml-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </a>
          </label>
        </div>

        {/* Submit Button */}
        <button
          className={`linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${!termsAccepted ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleSignUp}
          disabled={!termsAccepted}
        >
          Create Account
        </button>
      </div>
    </div>
  );
}
