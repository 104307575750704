/* global chrome */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import axios from "axios";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [capVal, setCapVal] = useState(null);
  

  const handleSignIn = async () => {
    try {
        const response = await axios.post("https://praktbackendnew-production.up.railway.app/api/auth/login", {
            username: email,
            password: password
        }, {
            headers: {
                'recaptcha': capVal,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        console.log("RESPONSE: ", response);

        const { accessToken } = response.data;
        Cookies.set("jwt", accessToken, { expires: keepLoggedIn ? 7 : null });

        // Check if chrome.runtime.sendMessage is available
        if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
            // Send the token to the Chrome extension
            const sendMessagePromise = new Promise((resolve, reject) => {
                chrome.runtime.sendMessage("eeedhncneejmgokflckdfjagaidichbb", { type: 'SEND_JWT', token: accessToken }, function(response) {
                    if (chrome.runtime.lastError) {
                        reject(chrome.runtime.lastError);
                    } else {
                        resolve(response);
                    }
                });
            });

            const extensionResponse = await sendMessagePromise;
            console.log("Received response from extension:", extensionResponse);
        } else {
            console.error("chrome.runtime.sendMessage is not available.");
        }

        // Navigate after the message is successfully sent
        navigate("/admin/default");
    } catch (error) {
        console.log(error);
        setError("Invalid credentials, please try again.");
    }
};


  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        {error && <p className="mb-4 text-sm text-red-500">{error}</p>}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="email@prakt.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <ReCAPTCHA
        sitekey="6LdG0_QpAAAAAG3ztPYRP7gLXwlTUEb-94hw6maR"
        onChange={val => setCapVal(val)}
        />,
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox checked={keepLoggedIn} onChange={() => setKeepLoggedIn(!keepLoggedIn)} />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="#"
          >
            Forgot Password?
          </a>
        </div>
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSignIn}
        >
          Sign In
        </button>
        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </Link>
        </div>
      </div>
    </div>
  );
}
