import React from "react";
import Card from "components/card";
import { MdBarChart } from "react-icons/md";
import Cookies from "js-cookie";  // Import the js-cookie library

const Widget = ({ icon, title, subtitle }) => {
  const handlePurchaseClick = async () => {
    try {
      const jwtToken = Cookies.get("jwt");  // Get the JWT token from the cookies

      const response = await fetch("https://praktbackendnew-production.up.railway.app/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwtToken}`  // Include the JWT token in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const sessionUrl = await response.text();
      window.location.href = sessionUrl;
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] relative">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
      </div>

      {title === "Tokens" && (
        <button
        className="absolute top-0 right-0 m-2 px-4 py-2 bg-[#4318ff] text-white rounded-lg hover:bg-[#4318ff]"
        onClick={handlePurchaseClick}
      >
        Köp tokens
      </button>
      )}
    </Card>
  );
};

export default Widget;
