// Dashboard.js

import React, { useEffect, useState, useCallback } from "react";
import MiniCalendar from "components/calendar/MiniCalendar";
import { MdBarChart } from "react-icons/md";
import { columnsDataCheck } from "./variables/columnsData";
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import axios from "axios";
import Cookies from "js-cookie";

const Dashboard = () => {
  const [kontaktadeCount, setKontaktadeCount] = useState(0);
  const [tableDataCheck, setTableDataCheck] = useState([]);
  const [tokens, setTokens] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const token = Cookies.get("jwt");
      const [profileResponse, tokenResponse] = await Promise.all([
        axios.get("https://praktbackendnew-production.up.railway.app/api/v1/profile/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get("https://praktbackendnew-production.up.railway.app/api/v1/profile/tokens", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      setTableDataCheck(profileResponse.data);

      const count = profileResponse.data.reduce((acc, profile) => {
        if (profile.contacted === "true") {
          return acc + 1;
        }
        return acc;
      }, 0);
      setKontaktadeCount(count);

      // Update token count
      setTokens(tokenResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Kontaktade"}
          subtitle={kontaktadeCount}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Tokens"}
          subtitle={tokens}
        />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2"></div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
            onEmailFetchSuccess={fetchData} // Pass the callback function
            setTokens={setTokens} // Pass the setTokens function
          />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic onSaveSuccess={fetchData} />
          <MiniCalendar />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <div className="grid grid-cols-1 rounded-[20px]"></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
